import { PageProps } from 'gatsby';
import { FC } from 'react';
import { Helmet } from 'react-helmet';

import { DocumentList } from '../components';
import { useTranslateData } from '../hooks';
import {
  DocumentTypes,
  FAQ,
  HowItWorksText,
  HowItWorksVideo,
  LetUsTry,
  MainFeatured,
  MassMedia,
  Partners,
  Presentation,
  ProvideRecognition,
  WhatWeDo,
  WhatYouReceive,
  WhoWeHelp,
} from '../sections';

type IIndexNavigation = {
  metaTitle: string
  metaDescription: string
  metaKeywords: string
  url: string;
};

const IndexPage: FC<PageProps> = () => {
  const { metaTitle, metaDescription, metaKeywords } = useTranslateData<IIndexNavigation>(
    'navigationByKey.main'
  ) as IIndexNavigation;
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <link rel="icon" type="image/png" href="/favicon.png"></link>
        <link rel="shortcut icon" href="/favicon.ico" type="image/vnd.microsoft.icon" />
      </Helmet>
      <MainFeatured />
      <DocumentList />
      <WhatWeDo />
      <HowItWorksVideo />
      <HowItWorksText />
      <DocumentTypes />
      <WhoWeHelp />
      <LetUsTry />
      <WhatYouReceive />
      <Partners />
      <FAQ />
      <MassMedia />
      <ProvideRecognition />
      <Presentation />
    </>
  );
};

export default IndexPage;
